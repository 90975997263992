<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs />

      <v-menu
          v-model="showColumnsMenu"
          :close-on-content-click="false"
          :nudge-width="450"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-5 inlineblock" v-bind="attrs" v-on="on" icon
              ><v-icon color="grey">mdi-cog-outline</v-icon></v-btn
            >
          </template>
          <v-card style="overflow:hidden;">
            <v-chip
              style="width:225px; margin:15px;"
              :color="allColumnsDisplayed ? 'primary' : 'grey'"
              @click="toggleAllColumns"
              text-color="white"
            >
              <v-avatar v-if="allColumnsDisplayed" left>
                <v-icon>mdi-check</v-icon>
              </v-avatar>
              {{ $t("filters.allfilters") }}
            </v-chip>
            <v-row no-gutters style="margin-right:50px;">
              <v-col cols="6">
                <v-list>
                  <v-list-item v-for="(column, index) in columns.slice(0, columns.length / 2)" :key="index">
                    <template>
                      <v-chip
                        style="width:225px;"
                        :color="column.active ? 'primary' : 'grey'"
                        @click="column.active = !column.active"
                        text-color="white"
                      >
                        <v-avatar v-if="column.active" left>
                          <v-icon>mdi-check</v-icon>
                        </v-avatar>
                        {{ column.title }}
                      </v-chip>
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col no-gutters cols="6">
                <v-list>
                  <v-list-item
                    v-for="(column, index) in columns.slice(columns.length / 2, columns.length)" :key="index">
                    <template>
                      <v-chip
                        style="width:225px;"
                        :color="column.active ? 'primary' : 'grey'"
                        @click="column.active = !column.active"
                        text-color="white"
                      >
                        <v-avatar v-if="column.active" left>
                          <v-icon>mdi-check</v-icon>
                        </v-avatar>
                        {{ column.title }}
                      </v-chip>
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-btn icon absolute top right @click="showColumnsMenu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card>
        </v-menu>

      <v-text-field
        class="ml-3 mt-7"
        v-model="generalSearch"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        filled
        clearable
        :loading="isSearching"
        @keydown="handleSearch"
        @click:clear="handleSearch"
        style="display:inline-block;min-width:400px;"
      ></v-text-field>
      
      <div class="top-right-fab">
        <v-btn
          class="techno-green"
          fab
          @click="isAddingUser=true" 
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <v-container :loading="!users" fluid>

      <div v-if="!users">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <v-skeleton-loader
                type="table-heading, divider, table-heading, divider, table-heading, divider"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-show="users">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <div ref="table"></div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <NamingDialog
      v-if="isAddingUser"
      v-model="isAddingUser"

      :headlineName="$t('user.add_user')"
      :labelName="$t('users.email')"

      :validationRules="emailRules"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isAddingUser = false)"
      :actionName="$t('general.add')"
      :actionFunction="create"
    />
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";

import { mapMutations, mapActions, mapState } from "vuex";
import UserService from "@/services/user.service";
import Breadcrumbs from "@/components/Breadcrumbs";
import NamingDialog from "@/components/dialogs/NamingDialog"
import { getLanguage, getLanguageWithoutRegion, parseError, getTabulatorLangsObject, objectMatchSearch, displayPaginationInfo } from "@/utils/utils"
import { emailRules } from "@/utils/validation";
import moment from 'moment';

var table = null;

export default {
  components: {
    Breadcrumbs,
    NamingDialog,
  },
  data() {
    var scope = this;

    return {
      users: null,
      locale: this.$i18n.locale,
      isAddingUser: false,
      searchInput: "",
      showColumnsMenu: false,
      selected: [],
      tabulatorColumns: [],
      columns: [
        {
          field: "formattedName",
          title: this.$t("users.name"),
          minWidth: 200,
          active: true,
        },
        {
          field: "username",
          title: this.$t("users.username"),
          minWidth: 300,
          active: true,
          formatter: function (cell) {
            let value = cell.getValue();
            let user = cell.getData();

            let html = `<span>${value}</span>`;
            if (user.isWelder && user.isWelderExpired) {
              html += `<i title="` + scope.$t('user.welder_expired_title') + `" style="color:red;position:absolute;right:5px;" class="mdi mdi-flag"></i>`;
            }
            return html;
          },
        },
        {
          field: "formattedDealer",
          title: this.$t("users.dealer"),
          minWidth: 200,
          active: true,
        },
        {
          field: "formattedEngineeringDepartments",
          title: this.$t("general.engineering_departments"),
          minWidth: 200,
          active: true,
        },
        {
          field: "formattedRole",
          title: this.$t("users.role"),
          minWidth: 200,
          active: true,
        },
        {
          field: "isActive",
          title: this.$t("users.active"),
          minWidth: 100,
          formatter:"tickCross", formatterParams:{ crossElement: false },
          hozAlign:"center",
          active: true,
        },
        {
          field: "isInstaller",
          title: this.$t("users.installer"),
          minWidth: 100,
          formatter:"tickCross", formatterParams:{ crossElement: false },
          hozAlign:"center",
          active: true,
        },
        {
          field: "isWelder",
          title: this.$t("users.welder"),
          minWidth: 100,
          formatter:"tickCross", formatterParams:{ crossElement: false },
          hozAlign:"center",
          active: true,
        },
        {
          field: "formattedPhone",
          title: this.$t("users.phone"),
          minWidth: 125,
          active: false,
        },
        {
          field: "welderCardCwb",
          title: this.$t("users.welderCardCwb"),
          active: false,
        },
        {
          field: "welderExpirationDateCwb",
          title: this.$t("users.welderExpirationDateCwb"),
          minWidth: 100,
          active: false,
        },
        {
          field: "formattedLastActivity",
          title: this.$t("users.lastActivity"),
          minWidth: 150,
          active: true,
          formatter: function dateTimeFormatter(cell) {
              let value = cell.getValue();

              if (value && value.length > 0) {
                let formattedDate = moment(value).format('YYYY/MM/DD HH:mm:ss');
                return formattedDate;
              }
              return scope.$t("users.lastActivityNever");
            },
        },
        {
          field: "formattedInvitationSent",
          title: this.$t("users.invitationSent"),
          minWidth: 250,
          active: false,
          formatter: function dateTimeFormatter(cell) {
              let passwordSet = cell.getValue();
              let user = cell.getData();

              if (passwordSet) {
                return scope.$t("users.invitationAnswered");
              }
              else {
                if (user.passwordSentAt) {
                  return scope.$t("users.invitationSent") + moment(user.passwordSentAt).format('YYYY/MM/DD HH:mm:ss');
                }
                else {
                  return scope.$t("users.neverSent");
                }
              }
          },
        }
      ],
      generalSearch: '',
      isSearching: false
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    allColumnsDisplayed() {
      for (let column of this.columns) {
        if (!column.active) {
          return false;
        }
      }
      return true;
    },
    emailRules() {
      return emailRules;
    }
  },
  watch: {
    showColumnsMenu() {
      if (!this.showColumnsMenu) {
        this.updateTabulatorColumns();
        table.setColumns(this.tabulatorColumns);

        this.refreshUsers();
      }
    },
  },
  methods: {
    ...mapMutations({
      openToast: "openToast",
      showErrorMessage: 'showErrorMessage'
    }),
    ...mapActions({
      logout: "logout"
    }),
    getUsers() {
      
      console.log("Reloading users...");
      var scope = this;

      return new Promise((resolve, reject) => {

      UserService.getUsers(getLanguage())
        .then(function(response) {
          scope.users = response.data;

          table.setData(scope.users);

          resolve(response.data);
        })
        .catch(function(error) {
          // handle error
          let errorMsg = parseError(error);
          //console.log(errorMsg);
          scope.showErrorMessage(errorMsg);
          if (error.toString().includes("401")) {
            scope.logout(this.$socket);
          } else if (
            error.toString().includes("500") &&
            scope.$router.currentRoute.path.includes("/users")
          ) {
            console.log("Redirecting to home from users...");
            scope.$router.push("/");
          }

          reject(errorMsg);
        })
    })
    },
    async create(username) {
      let data = { locale: this.locale, username: username };

      // On cree l'utilisateur seulement s'il n'existe pas déjà
      let exists = await UserService.checkUser(data);
      if(exists.data) {
        this.openToast({ message: this.$t("customer.email_already_used"), duration: 15000, color: 'red'});
        return;
      }

      let response = await UserService.createUser(data);
      if (response.status == 200) {
        this.$router.push('/user/' + response.data.id);
      } else {
        this.openToast({ message: response, duration: 15000, color: 'red'});
      }
    },
    selectUser: function(user) {
      this.$router.push("/user/" + user.id);
    },
    updateTabulatorColumns() {
      this.tabulatorColumns = this.columns.filter(c => c.active);

      let displayedColumnKeys = this.tabulatorColumns.map(c => c.field);
      localStorage.setItem('displayedUsersColumnKeys', displayedColumnKeys);
    },
    toggleAllColumns() {
      let allColumnsDisplayed = !this.allColumnsDisplayed;

      this.columns.forEach((column) => {
        column.active = allColumnsDisplayed;
      });
    },
    initTabulator() {

      var scope = this;

      let columnsLangData = { 
        "formattedName": this.$t("users.name"),
        "username": this.$t("users.username"),
        "formattedDealer": this.$t("users.dealer"),
        "formattedEngineeringDepartments": this.$t("general.engineering_departments"),
        "formattedRole": this.$t("users.role"),
        "isActive": this.$t("users.active"),
        "isInstaller": this.$t("users.installer"),
        "isWelder": this.$t("users.welder"),
        "formattedPhone": this.$t("users.phone"),
        "welderCardCwb": this.$t("users.welderCardCwb"),
        "welderExpirationDateCwb": this.$t("users.welderExpirationDateCwb"),
        "formattedLastActivity": this.$t("users.lastActivity"),
        "formattedInvitationSent": this.$t("users.invitationSent"),
      };

      table = new Tabulator(this.$refs.table, {
        layout: "fitColumns",
        columns: this.tabulatorColumns,
        persistence: { sort: true, filter: true, columns: false },
        persistenceID: "persistenceUsersReport",
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 25, 50, 100],
        locale: getLanguageWithoutRegion(getLanguage()),
        langs: getTabulatorLangsObject(this.$i18n, columnsLangData),
        paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => displayPaginationInfo(pageSize, currentRow, currentPage, totalRows, totalPages, scope)
      });

      table.on("rowClick", function(e, row){
          scope.$router.push("/user/" + row.getData().id);
      });

      table.on("tableBuilt", function(){
        scope.getUsers();
      });
    },
    handleSearch() {
      let scope = this;
      this.isSearching = true;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.clearSearch();
      }, 1000);
    },
    clearSearch() {
      this.isSearching = false;
      
      this.refreshUsers();
    },
    refreshUsers() {
      let users = this.users;
      if (this.generalSearch) {
        users = users.filter(u => objectMatchSearch(u, this.generalSearch, this.tabulatorColumns.map(c => c.field)));
      }
      table.setData(users);
    }
  },
  mounted() {
    console.log('Users mounted')
    this.$store.dispatch("setBreadcrumbs", [
      { text: this.$t("users.users"), href: "/users" },
    ]);

    let displayedColumnKeys = localStorage.getItem('displayedUsersColumnKeys');
    if (displayedColumnKeys) {
      displayedColumnKeys = displayedColumnKeys.split(',');
      for (let column of this.columns) {
        column.active = displayedColumnKeys.find(k => column.field == k) != undefined;
      }
    }
    this.updateTabulatorColumns();
    this.initTabulator();
  },
  created: function() {
    console.log('Reports created')
  },
};
</script>

<style>
.badge-right-top {
  position: absolute!important;
  right: 20px;
  top: 20px;
}
.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}
tr {
  cursor: pointer;
}
td.text-start {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal !important;
}

.v-breadcrumbs {
  display: inline-block;
}
</style>